var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// @mui
import { Paper, Stack, Dialog, Button, TextField, DialogTitle, DialogActions, DialogContent, FormHelperText, } from '@mui/material';
import { DatePicker, CalendarPicker } from '@mui/x-date-pickers';
import { useState } from 'react';
// hooks
import useResponsive from '../../hooks/useResponsive';
// ----------------------------------------------------------------------
export default function DateRangePicker(_a) {
    var _b = _a.title, title = _b === void 0 ? 'Select date range' : _b, _c = _a.variant, variant = _c === void 0 ? 'input' : _c, 
    //
    startDate = _a.startDate, endDate = _a.endDate, 
    //
    onChangeStartDate = _a.onChangeStartDate, onChangeEndDate = _a.onChangeEndDate, 
    //
    open = _a.open, onClose = _a.onClose, 
    //
    isError = _a.isError, _d = _a.formHelperText, formHelperText = _d === void 0 ? 'End date must be later than start date' : _d, 
    //
    _e = _a.onChangeOnApproved, 
    //
    onChangeOnApproved = _e === void 0 ? true : _e, views = _a.views;
    var isDesktop = useResponsive('up', 'md');
    var isCalendarView = variant === 'calendar';
    var _f = useState(startDate), startDateClone = _f[0], setStartDateClone = _f[1];
    var _g = useState(endDate), endDateClone = _g[0], setEndDateClone = _g[1];
    var onSubmit = function () {
        onChangeStartDate(startDateClone);
        onChangeEndDate(endDateClone);
    };
    var handleSubmitData = function () {
        if (onChangeOnApproved) {
            onSubmit();
        }
        onClose();
    };
    return (_jsxs(Dialog, __assign({ fullWidth: true, maxWidth: isCalendarView ? false : 'xs', open: open, onClose: onClose, PaperProps: {
            sx: __assign({}, (isCalendarView && {
                maxWidth: 720,
            })),
        } }, { children: [_jsx(DialogTitle, __assign({ sx: { pb: 2 } }, { children: title })), _jsxs(DialogContent, __assign({ sx: __assign({}, (isCalendarView &&
                    isDesktop && {
                    overflow: 'unset',
                })) }, { children: [_jsx(Stack, __assign({ spacing: isCalendarView ? 3 : 2, direction: isCalendarView && isDesktop ? 'row' : 'column', justifyContent: "center", sx: {
                            pt: 1,
                            '& .MuiCalendarPicker-root': __assign({}, (!isDesktop && {
                                width: 'auto',
                            })),
                        } }, { children: isCalendarView ? (_jsxs(_Fragment, { children: [_jsx(Paper, __assign({ variant: "outlined", sx: { borderRadius: 2, borderColor: 'divider', borderStyle: 'dashed' } }, { children: _jsx(CalendarPicker, { date: onChangeOnApproved ? startDateClone : startDate, onChange: onChangeOnApproved ? setStartDateClone : onChangeStartDate }) })), _jsx(Paper, __assign({ variant: "outlined", sx: { borderRadius: 2, borderColor: 'divider', borderStyle: 'dashed' } }, { children: _jsx(CalendarPicker, { date: onChangeOnApproved ? endDateClone : endDate, onChange: onChangeOnApproved ? setEndDateClone : onChangeEndDate }) }))] })) : (_jsxs(_Fragment, { children: [_jsx(DatePicker, { label: "Start date", value: startDateClone, onChange: function () { return (onChangeOnApproved ? setStartDateClone : onChangeStartDate); }, renderInput: function (params) { return _jsx(TextField, __assign({}, params)); } }), _jsx(DatePicker, { label: "End date", value: endDateClone, onChange: function () { return (onChangeOnApproved ? setEndDateClone : onChangeEndDate); }, renderInput: function (params) { return _jsx(TextField, __assign({}, params)); } })] })) })), isError && (_jsx(FormHelperText, __assign({ error: true, sx: { px: 2 } }, { children: formHelperText })))] })), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ variant: "outlined", color: "inherit", onClick: onClose }, { children: "Cancel" })), _jsx(Button, __assign({ disabled: isError, variant: "contained", onClick: function () { return handleSubmitData(); } }, { children: "Apply" }))] })] })));
}
